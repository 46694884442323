$screen-sm-min: 37.5rem;
$screen-md-min: 60rem;
$screen-lg-min: 80rem;
$screen-xl-min: 120rem;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin container-full-width {
  width: 100%;
  max-width: 1408px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @include sm {
    padding-left: 32px;
    padding-right: 32px;
  }
  @include md {
    padding-left: 64px;
    padding-right: 64px;
  }
}
