@import 'styles/mixins';

.root {
  background-image: linear-gradient(rgb(255, 255, 255) 0%, rgb(247, 242, 242) 60%);
  position: fixed;
  z-index: 2;
  bottom: 0;
  width: 100%;
  border-radius: 20px 20px 0 0;
  text-align: center;
  padding: 14px 10px;
  border-top: 1px solid var(--palette-divider);
  color: var(--palette-text-primary);
  bottom: -100px;
  transition: bottom 500ms ease-in-out;

  p {
    margin: 0;
  }

  @include md {
    display: none;
  }
}

:global(.noScroll) .root,
:global(.scrollingUp) .root {
  bottom: 0;
}

.title {
  font-weight: 600;
  font-size: 1rem;
}

.description {
  font-size: 0.8rem;
}

.welcomeOinq {
  width: 105px;
  position: absolute;
  right: -20px;
  bottom: -5px;
  rotate: -18deg;
}
