@import 'styles/mixins';

.root {
  position: relative;
  justify-content: flex-end;
  display: none;
  padding-left: 15%;
  @include md {
    display: flex;
  }
}

.signUpForm {
  z-index: 1;
  max-width: 470px;
}

.welcomeOinq {
  display: none;
  position: absolute;
  left: -52px;
  bottom: 67px;
  animation-name: oinqIn;
  animation-duration: 1200ms;
  z-index: 0;

  @include sm {
    display: block;
    max-width: 180px;
  }

  @include lg {
    max-width: 260px;
  }
}

@keyframes oinqIn {
  0% {
    left: 300px;
    bottom: 0;
    opacity: 0;
  }
  50% {
    left: 250px;
    bottom: 0px;
    opacity: 0;
  }
  80% {
    left: -57px;
    bottom: 70px;
  }
  100% {
    left: -52px;
    bottom: 67px;
  }
}
