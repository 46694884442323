@import 'styles/mixins';

.root {
  padding-top: 45px;
  z-index: 1;
  flex-direction: column !important;
  gap: 40px !important;

  @include sm {
    padding-bottom: 20px;
  }

  @include md {
    padding-top: 24px;
    padding-bottom: 10px;
    flex-direction: row !important;
  }

  h1 {
    margin: 0;
    font-size: 2.5rem;

    strong {
      color: var(--palette-secondary-main);
    }

    @include md {
      font-size: 3.2rem;
    }

    @include lg {
      font-size: 4rem;
    }
  }

  .showOnMobileOnly {
    @include md {
      display: none;
    }
  }
}

.wrapper {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    opacity: 0.25;
    background-image: url('/assets/tick_icon_green.svg'), url('/assets/dollar_icon_yellow.svg'),
      url('/assets/tick_icon_green.svg');
    background-repeat: no-repeat;
    background-position: 80% 10%, 20% 95%, 95% 100%, 95% 12%;
    background-size: 35px, 40px, 40px;

    @include md {
      background-image: url('/assets/tick_icon_green.svg'), url('/assets/tick_icon_green.svg'),
        url('/assets/dollar_icon_yellow.svg'), url('/assets/dollar_icon_yellow.svg'),
        url('/assets/dollar_icon_yellow.svg');
      background-repeat: no-repeat;
      background-position: 10% 12%, 40% 75%, 35% 9%, 95% 12%, 5% 75%;
      background-size: 45px, 30px, 60px, 75px, 40px;
    }
  }
}

:global(.locale-en-gb) {
  .wrapper {
    &::after {
      background-image: url('/assets/tick_icon_green.svg'), url('/assets/pound_icon_yellow.svg'),
        url('/assets/tick_icon_green.svg');

      @include md {
        background-image: url('/assets/tick_icon_green.svg'), url('/assets/tick_icon_green.svg'),
          url('/assets/pound_icon_yellow.svg'), url('/assets/pound_icon_yellow.svg'),
          url('/assets/pound_icon_yellow.svg');
      }
    }
  }
}

:global(.locale-de-de),
:global(.locale-fr-fr) {
  .wrapper {
    &::after {
      background-image: url('/assets/tick_icon_green.svg'), url('/assets/euro_icon_yellow.svg'),
        url('/assets/tick_icon_green.svg');

      @include md {
        background-image: url('/assets/tick_icon_green.svg'), url('/assets/tick_icon_green.svg'),
          url('/assets/euro_icon_yellow.svg'), url('/assets/euro_icon_yellow.svg'),
          url('/assets/euro_icon_yellow.svg');
      }
    }
  }
}

.leftColumn {
  position: relative;

  @include md {
    padding-bottom: 70px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      background-image: url('/assets/dollar_icon_green_shadow.svg'),
        url('/assets/dollar_icon_yellow_shadow.svg');
      background-position: 30% 100%, 70% 85%;
      background-size: 80px, 50px;
      background-repeat: no-repeat;
    }
  }

  p {
    margin-bottom: 0;
  }
}

:global(.locale-en-gb) .leftColumn {
  @include md {
    &::after {
      background-image: url('/assets/pound_icon_green_shadow.svg'),
        url('/assets/pound_icon_yellow_shadow.svg');
    }
  }
}

:global(.locale-de-de) .leftColumn,
:global(.locale-fr-fr) .leftColumn {
  @include md {
    &::after {
      background-image: url('/assets/euro_icon_green_shadow.svg'),
        url('/assets/euro_icon_yellow_shadow.svg');
    }
  }
}
