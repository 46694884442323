@import 'styles/mixins';
$inputHeight: 3rem;
$inputSidePadding: 14px;
$labelTransitionProps: 200ms ease-in;

.root {
  display: flex;
  position: relative;

  flex-direction: column;
  label {
    color: var(--palette-text-secondary);
    margin-bottom: 0.25rem;
    margin-left: var(--dimensions-border-radius-default);
  }
  .inputContainer {
    min-width: 250px;
    height: $inputHeight;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    border-radius: $inputHeight / 2;
    border: solid 1px var(--palette-divider);
    transition: border-color $labelTransitionProps;
    .adornment {
      padding: 0 0.5rem;
      background-color: var(--palette-background-secondary);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      &:first-child {
        border-top-left-radius: calc(($inputHeight / 2) + 2px);
        border-bottom-left-radius: calc(($inputHeight / 2) + 2px);
      }
    }
    .input {
      flex-grow: 1;
      font-size: 1rem;
      align-self: stretch;
      padding: 0 $inputSidePadding;
      background-color: transparent;
      margin: 0;
      border: 0;
      outline: none;
      transition: 0.2s;
      border-radius: $inputHeight / 2;
      &:focus,
      &:active {
        outline: none;
        background-color: transparent;
      }
      &:hover:disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
      &:active {
        outline: none;
        background-color: transparent;
      }
      &:hover:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }
  }

  // if inside new placholder/label input style
  &.labelPlaceholder {
    @mixin selectedLabelStyle {
      font-size: 0.7rem;
      line-height: 0.7rem;
      top: -3px;
      left: 20px;
      padding: 0 6px;
      background: white;
      bottom: auto;
      color: var(--palette-secondary-main);
    }

    .input::placeholder {
      display: none;
      visibility: none;
      opacity: 0;
    }

    .labelText {
      position: absolute;
      top: 0;
      bottom: 2px;
      left: $inputSidePadding;
      line-height: $inputHeight;
      margin: 0;
      padding: 0;
      font-size: 0.85rem;
      pointer-events: none;
      color: var(--palette-text-primary);
      transition: all $labelTransitionProps;
    }

    input:-webkit-autofill {
      + .labelText {
        @include selectedLabelStyle;
      }
    }

    &:focus-within .inputContainer {
      border-color: var(--palette-secondary-main);
    }

    &:focus-within,
    &.inputFilled {
      .labelText {
        @include selectedLabelStyle;
      }
    }
  }
}

.showPasswordBtn {
  width: $inputHeight;
  height: $inputHeight;
  position: absolute;
  right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  transition: opacity $labelTransitionProps;

  &:hover {
    opacity: 0.9;
  }

  svg {
    width: 18px;
    stroke: #491ec4;
    fill: red;
  }
}
