@import 'styles/mixins';

.root {
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 1;
  position: relative;
  background-image: url('/assets/dollar_icon_green.svg'), url('/assets/dollar_icon_yellow.svg');
  background-position: 75% 7%, 15% 9%;
  background-size: 50px, 30px;
  background-repeat: no-repeat;

  @include sm {
    background-position: 50% 10%, 30% 90%;
    background-size: 60px, 50px;
    padding-top: 175px;
    padding-bottom: 175px;
  }
}

.wrapper {
  position: relative;

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0.25;
    background-image: url('/assets/tick_icon_green.svg');
    background-position: 15% 90%;
    background-size: 40px;
    background-repeat: no-repeat;

    @include sm {
      background-image: url('/assets/tick_icon_green.svg'), url('/assets/dollar_icon_yellow.svg'),
        url('/assets/tick_icon_green.svg');
      background-position: 10% 35%, 75% 25%, 85% 65%;
      background-size: 35px, 50px, 45px;
    }
  }
}

:global(.locale-en-gb) {
  .root {
    background-image: url('/assets/pound_icon_green.svg'), url('/assets/pound_icon_yellow.svg');
  }

  .wrapper::after {
    @include sm {
      background-image: url('/assets/tick_icon_green.svg'), url('/assets/pound_icon_yellow.svg'),
        url('/assets/tick_icon_green.svg');
    }
  }
}

:global(.locale-de-de) {
  .root {
    background-image: url('/assets/euro_icon_green.svg'), url('/assets/euro_icon_yellow.svg');
  }

  .wrapper::after {
    @include sm {
      background-image: url('/assets/tick_icon_green.svg'), url('/assets/euro_icon_yellow.svg'),
        url('/assets/tick_icon_green.svg');
    }
  }
}

.title {
  font-size: var(--dimensions-font-size-xlarge);
  font-weight: 600;
}

.ctaButton {
  margin-top: 20px;
}
